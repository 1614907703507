<template>
  <div class="instructions">
    <h5>INSTRUCŢIUNI DE PLATĂ</h5>
    <strong>Banca:</strong> ING Bank România<br>
    <strong>Cont:</strong> RO26 INGB 0000 9999 0503 6489
  </div>
</template>

<script>
export default {
  name: "Instructions"
}
</script>

<style scoped>

</style>