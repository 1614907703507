import AWSAppSyncClient from "aws-appsync";
const options = {
    defaultOptions: {
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
        mutate: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        }
    }
};
export const ApolloClient = new AWSAppSyncClient({
    url: process.env.VUE_APP_AWS_GRAPHQL_ENDPOINT,
    region: process.env.VUE_APP_AWS_GRAPHQL_REGION || 'eu-central-1',
    auth: {
        type: 'OPENID_CONNECT',
        jwtToken: () => window.localStorage.getItem('id_token')
    }
}, options);