import {ApolloClient} from "@/appsync"
import _ from "lodash"
import listInvoices from '@/appsync/queries/list'
import getInvoice from '@/appsync/queries/get'
import createInvoice from '@/appsync/mutations/create'
import updateInvoice from '@/appsync/mutations/update'
import listLatestInvoices from '@/appsync/queries/listLatestInvoices'
import listFiles from '@/appsync/queries/listFiles'
import deleteFile from '@/appsync/mutations/deleteFile'
import getRate from '@/appsync/queries/getRate'
import { webAuth } from "@/auth"
import cleanDeep from "clean-deep"
import dayjs from "dayjs"
export default {
    setup: async function ({ dispatch, rootGetters, commit}) {
        window.console.log('try setup')
        if( rootGetters.loaders.indexOf('getRate') === -1 ){
            await dispatch('getRate', dayjs().format('YYYY-MM-DD'))
        }
        if( rootGetters.loaders.indexOf('list') === -1 ){
            await dispatch('list')
        }
        if( rootGetters.loaders.indexOf('latest') === -1 ){
            await dispatch('latest')
        }
        commit('READY', true)
    },
    changeTheme: function ({commit}, value){
        commit('SET_THEME', value)
    },
    loader: function({commit}, l){
        commit('TOGGLE_LOADER', l)
    },
    set_logged_user: function({commit}, user){
        commit('SET_LOGGED_USER', user || JSON.parse(localStorage.getItem('user')) )
    },
    clear: async function({commit}){
        commit('CLEAR')
    },
    clearFiles: function ({commit}){
        commit('CLEAR_FILES')
    },
    patchFiles: function({commit}, file){
        commit('PATCH_FILES', file)
    },
    clearInvoice: function ({commit}){
        commit('CLEAR')
    },
    deleteFile: async function ({dispatch, commit}, key) {
        dispatch('loader', 'deleteFile', {root: true})
        try{
            let deleted = await ApolloClient.mutate({
                mutation: deleteFile,
                variables: {
                    key
                }
            })
            window.console.log('files', deleted )
            if( deleted.data.deleteFile.indexOf( 'DeleteMarker=true' ) >= 0 ){
                commit('REMOVE_FILE', key)
            }
        } catch (e) {
            window.console.log(e)
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', title: 'Could not get settings', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'deleteFile', {root: true})
    },
    listFiles: async function ({dispatch, commit}, prefix) {
        dispatch('loader', 'listFiles', {root: true})
        try{
            prefix = prefix.replace('/', '')
            let get = await ApolloClient.query({
                query: listFiles,
                variables: {
                    prefix
                }
            })
            window.console.log('files', prefix, get)
            commit( 'SET_FILES_LIST', get.data.listFiles )
        } catch (e) {
            window.console.log(e)
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', title: 'Could not get settings', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'listFiles', {root: true})
    },
    getRate: async function ({dispatch, commit}, date) {
        dispatch('loader', 'getRate', { root: true })

        try{
            let variables = {
                date: date
            }
            let rate = await ApolloClient.query({
                query: getRate,
                variables: variables
            })
            commit('SET_RATE', rate.data.getRate )
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'getRate', { root: true })
    },
    latest: async function ({dispatch, commit}) {
        dispatch('loader', 'latest', { root: true })
        try{
            let variables = {
                limit: 15
            }
            let list = await ApolloClient.query({
                query: listLatestInvoices,
                variables: variables
            })
            window.console.log('latest', list)
            commit('SET_LATEST_LIST', list.data.listLatestInvoices.items )

        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'latest', { root: true })
    },
    get: async function ({dispatch, commit}, id) {
        dispatch('loader', 'get', { root: true })
        try{
            let variables = {
                id: id
            }
            let invoice = await ApolloClient.query({
                query: getInvoice,
                variables: variables
            })
            window.console.log('invoice get', invoice)
            commit('SET', invoice.data.getInvoice )
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'get', { root: true })
    },
    list: async function ({dispatch, commit, getters}) {
        dispatch('loader', 'list', { root: true })
        try{
            let variables = {
                limit: 200
            }
            if( getters.nextToken !== null ){
                variables.nextToken = getters.nextToken
            }
            let list = await ApolloClient.query({
                query: listInvoices,
                variables: variables
            })
            commit('SET_LIST', list.data.listInvoices.items )
            commit('SET_NEXT_TOKEN', list.data.listInvoices.nextToken )

        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                window.console.log('err', webAuth)
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'list', { root: true })
    },
    create: async function ({dispatch, commit}, form) {
        dispatch('loader', 'create', { root: true })
        try{
            //form = new Location(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            if( ! _.isUndefined( form.id ) ){
                delete form.id
            }
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            let updated = await ApolloClient.mutate({
                mutation: createInvoice,
                variables: {
                    input: form
                }
            })
            console.log('created', updated)
            if( ! _.isNull( updated.data.createInvoice ) ){
                commit( 'ADD', updated.data.createInvoice )
                commit( 'ADD_LATEST', updated.data.createInvoice )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'create', { root: true })
    },
    update: async function ({dispatch, commit}, form) {
        dispatch('loader', 'update', { root: true })
        try{
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            let updated = await ApolloClient.mutate({
                mutation: updateInvoice,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.updateInvoice ) ){
                commit('SET', updated.data.updateInvoice )
                commit( 'PATCH_LIST', updated.data.updateInvoice )
                commit( 'ADD_LATEST', updated.data.updateInvoice )
            }
        } catch (e) {
            window.console.log('e', e)
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'update', { root: true })
    }
}