export default {
    setup: false,
    loaders: [],
    log: [],
    logged_user: undefined,
    color_scheme: 'light',
    list: [],
    nextToken: null,
    euro: {
        rate: 0,
        date: ''
    },
    invoice: undefined,
    latest: [],
    files_list: []
}