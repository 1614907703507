<template>
  <div :class="!readOnly ? 'editable' : ''">
    <h2>FACTURĂ</h2>
    <div class="invoice-serie-nr">
      <strong>Seria:</strong>
      <input :disabled="readOnly" v-model="serie"/>
      <strong>Numărul:</strong>
      <input :disabled="readOnly" type="number" v-model="nr"/>
    </div>
    <div class="invoice-date"><strong>Data:</strong> <el-date-picker  :disabled="readOnly" v-model="date" type="date" placeholder="Pick a day" value-format="yyyy-MM-dd"></el-date-picker></div>
    <br>
    <div><strong>SC. Curly Themes SRL</strong></div>
    <div>Str. Soseaua Pantelimon nr 266,
      Bloc D, Apartament 91, Etaj 11, Sector 2, 021613 Bucureşti
    </div>
    <br>
    <div>
      Codul de înregistrare pentru scopuri de TVA: <strong>RO34559091</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "Emitent",
  props: ['form', 'read-only'],
  computed: {
    serie: {
      get: function(){
        return this.form.serie
      },
      set: function (n){
        this.$emit('input-serie', n)
      }
    },
    nr: {
      get: function(){
        return parseInt(this.form.nr || 0)
      },
      set: function (n){
        this.$emit('input-nr', parseInt(n.replace(/[^0-9]+/g, '')))
      }
    },
    date: {
      get: function(){
        return this.form.date
      },
      set: function (n){
        this.$emit('input-date', n )
        //store.dispatch('get')
      }
    }
  },
  filters: {
    invoice_nr: function (n) {
      let out = ''
      if (parseInt(n) >= 10 && parseInt(n) <= 99) {
        out = `0${n}`
      } else if (parseInt(n) < 10) {
        out = `00${n}`
      } else {
        out = n
      }
      return out
    }
  }
}
</script>

<style lang="scss">
.invoice-date{
  display: flex;

  .el-date-editor.el-input, .el-date-editor.el-input__inner,
  .el-input.is-disabled .el-input__inner{
    width: auto;
    background: transparent;
    border: none;
    height: inherit;
    line-height: inherit;
    font-size: inherit;
    position: relative;
    flex-basis: 105px;
  }

  * {
    line-height: inherit;
    font-size: inherit;
    position: relative;
  }
  input{
    border: none;
    background-color: transparent;
    color: var(--color-text);
    outline: none;
    width: 100%;
    padding: 0 0 0 10px!important;
    height: inherit;
    line-height: inherit;
    font-size: inherit;
  }
  .el-input__prefix{
    display: none;
  }
  .el-input__suffix{
    position: absolute;
  }
}
  .invoice-serie-nr{
    display: grid;
    grid-template-columns: 1fr 40px 1fr 100px;

    input{
      border: none;
      background-color: transparent;
      color: var(--color-text);
      outline: none;
      width: 100%;
      text-align: left;
      padding: 0 10px;
    }
  }
</style>