<template>
  <el-container>
    <span class="fas fa-fw fa-adjust" v-on:click.prevent="changeTheme()"></span>
    <el-header>
      <el-row>
        <el-col :span="12">
          <select v-model="selector" class="menu-mobile">
            <option value="Inbox">Mesagerie</option>
          </select>
          <div class="menu">
            <router-link :to="{ name: 'Inbox' }">Mesagerie</router-link>
            <router-link :to="{ name: 'NewInvoice' }">Factura noua</router-link>
            <router-link :to="{ name: 'Invoices'}"><span class="fal fa-history fa-fw"></span>Facturi emise</router-link>
          </div>
        </el-col>
        <el-col :span="12" align="right" class="menu">
          <a href="#" v-on:click.prevent="$auth.logout()"><span class="fal fa-fw fa-power-off"></span>Logout</a>
        </el-col>
      </el-row>
    </el-header>
    <el-main>
      <router-view class="router-main-view" v-loading="loading"></router-view>
    </el-main>
  </el-container>
</template>

<script>
import store from "@/store"
export default {
  name: 'App',
  mounted: function(){
    this.setTheme(this.theme)
    let vm = this
    store.subscribe((mutation) => {
      if (mutation.type === 'LOG') {
        vm.$message({
          message: mutation.payload.message,
          type: mutation.payload.type
        });
      }
    })
  },
  data: function(){
    return {
      selector: null
    }
},
  computed:{
    loading: function (){
      return store.getters.loading
    },
    theme: function(){
      return store.getters.color_scheme
    }
  },
  methods: {
    changeTheme: function(){
      store.dispatch('changeTheme', this.theme === 'light' ? 'dark' : 'light')
    },
    setTheme: function(t){
      document.body.classList.add(`scheme--${t}`);
    }
  },
  watch: {
    theme: function(n){
      document.body.classList.remove('scheme--dark')
      document.body.classList.remove('scheme--light')
      this.setTheme(n)
    }
  }
}
</script>
<style lang="scss">
@import "./assets/scss/varaibles";
.el-main{
  position: relative;
}
.menu-mobile{
  display: none;
}
.menu{
  display: none;

  @include breakpoint(tablet){
    display: block;
  }

  a{
    color: black;
    text-decoration: none;
    font-weight: 500;

    & + a{
      margin-left: 2.5rem;
    }
    .fa-fw{
      margin-right: 0.5rem;
    }

    &.router-link-active{
      opacity: 0.45;
      cursor: text;

      &:hover{
        text-decoration: none;
      }
    }

    @at-root body.scheme--dark &{
      color: white;
    }

    &:hover{
      text-decoration: underline;
    }
  }
}
</style>
