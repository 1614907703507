<template>
  <div class="header">
    <el-row>
      <el-col :span="13">
        <img class="logo logo--white" src="@/assets/curly-logo.svg">
        <img class="logo logo--dark" src="@/assets/curly-logo-dark.svg">
      </el-col>
      <el-col :span="10" :offset="1">
        <Emitent :form="form" v-on:input-serie="setSerie" v-on:input-nr="setNr" v-on:input-date="setDate" :read-only="form.status === 2"></Emitent>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <Client v-model="edit_form.to" :read-only="form.status === 2" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Emitent from "@/components/Emitent"
import Client from "@/components/Client"
export default {
name: "InvoiceHeader",
  components: {
    Emitent,
    Client
  },
  props: ['form'],
  computed: {
    edit_form: {
      get: function(){
        return this.form
      },
      set: function (n){
        this.$emit('input', n)
      }
    }
  },
  methods: {
    setSerie: function (n){
      this.edit_form.serie = n
    },
    setNr: function (n){
      this.edit_form.nr = n
    },
    setDate: function (n){
      this.edit_form.date = n
    }
  }
}
</script>

<style scoped>

</style>