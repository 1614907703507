import gql from 'graphql-tag'
export default gql `
    query getRate($date: ID!){
        getRate(
            date: $date
        ){
            date
            exchange_date
            rate
        }
    }
`