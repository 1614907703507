<template>
  <div class="invoice-table">
    <div class="invoice-table__header invoice-table__row">
      <div>DENUMIRE SERVICII</div>
      <div>U.M.</div>
      <div>CANT.</div>
      <div>Preţ Unitar</div>
      <div>Valoare fără TVA</div>
      <div>Valoare TVA</div>
    </div>
    <div v-for="(item, index) in form.items" :key="index" class="invoice-table__row" :class="!readOnly ? 'editable' : ''">
      <div>
        <textarea :disabled="readOnly" v-model="edit_form.items[index].name" placeholder="Denumire serviciu facturabil"></textarea>
        <input :disabled="readOnly" class="note" v-model="edit_form.items[index].note" placeholder="Extra descriere serviciu">
      </div>
      <div><input :disabled="readOnly" v-model="edit_form.items[index].unit" class="center"/></div>
      <div><input :disabled="readOnly" type="number" step="1" min="0" @keydown="filterKey" v-model="edit_form.items[index].qty"
                  @input="filterInput($event, index)" class="center"/></div>
      <div v-on:click.prevent="showPrice(index)">
        <template v-if="edit_price">
          <span class="note">{{ (item.price_eu * euro) | currency }}</span>
          <input :disabled="readOnly" ref="price" type="number" class="right" v-model="edit_form.items[index].price_eu" @blur="edit_price = false"
                 @input="setPrice($event, index)"/>
        </template>
        <template v-else>{{ (item.price_eu * euro) | currency }}</template>
      </div>
      <div>{{ item.price * item.qty | currency }}</div>
      <div>{{ ((19 / 100) * ((item.price_eu * euro) * item.qty)) | currency }}
        <el-button v-if="!readOnly" class="remove-item-button" type="text" size="mini" v-on:click.prevent="deleteItem(index)"><span
            class="far fa-times"></span></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
name: "InvoiceItems",
  props: ['form', 'euro'],
  methods: {
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === '.')
        return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === 'e')
        return e.preventDefault();
    },
    filterInput(e, i) {
      this.edit_form.items[i].value = e.target.value.replace(/[^0-9]+/g, '');
    },
    deleteItem: function (index) {
      let vm = this
      this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        vm.edit_form.items.splice(index, 1)
        this.$message({
          type: 'success',
          message: 'Delete completed'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    setPrice: function (e, i) {
      this.edit_form.items[i].price = e.target.value * this.euro
    },
    showPrice: function (i) {
      let vm = this
      this.edit_price = true
      this.$nextTick(function () {
        if (!_.isUndefined(vm.$refs[`price`]) && vm.$refs[`price`].length > 0) {
          vm.$refs[`price`][i].focus()
        }
      })
    }
  },
  computed: {
    readOnly: function(){
      return this.form.status === 2
    },
    edit_form: {
      get: function(){
        return this.form
      },
      set: function (n){
        this.$emit('input', n)
      }
    }
  },
  data: function (){
    return {
      edit_price: false
    }
  }
}
</script>

<style scoped>

</style>