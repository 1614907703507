<template>
  <div>
    <div class="breadcrumbs">
      <div v-on:click.prevent="path = '/'"><span class="fas fa-fw fa-home"></span></div>
      <template v-for="(c, ci) in crumbs">
        <div class="fa-fw far fa-long-arrow-right" :key="`bb-${ci}`"></div>
        <div :key="`bbb-${ci}`" v-on:click.prevent="goTo(c)">{{c}}</div>
      </template>
      <div class="upload" v-if="crumbs.length > 2">
        <el-upload
            class="upload-demo"
            action=""
            :show-file-list="false"
            multiple
            :http-request="uploadToS3"
            :before-upload="beforeAvatarUpload">
          <div><el-button type="primary" round size="mini">+ Adauga fisiere</el-button></div>
        </el-upload>
      </div>
    </div>
    <div class="file-list">
      <template v-if="path === '/'">
        <div class="file-list__item file-list__item--folder" v-on:click.prevent="path += 'extrase'">
          <span class="fad fa-fw fa-folder-open"></span> Extrase
        </div>
        <div class="file-list__item file-list__item--folder" v-on:click.prevent="path += 'acte'">
          <span class="fad fa-fw fa-folder-open"></span> Acte
        </div>
      </template>
      <template v-else-if="path.split('/').length === 2">
        <template v-for="n in 2024">
          <div :key="`year-${n}`" v-if="n >= 2020" class="file-list__item file-list__item--folder" v-on:click.prevent="path += `/${n}`">
            <span class="fad fa-fw fa-folder-open"></span> {{n}}
          </div>
        </template>
      </template>
      <template v-else-if="path.split('/').length === 3">
        <template v-for="n in 12">
          <div :key="`mth-${n}`" class="file-list__item file-list__item--folder" v-on:click.prevent="path += `/${getMonthValue(n)}`">
            <span class="fad fa-fw fa-folder-open"></span> {{getMonthValue(n)}}
          </div>
        </template>
      </template>
      <template v-else>
        <template v-if="files_list.length > 0">
          <div class="files">
            <template v-for="(group, day) in files">
              <h5 :key="day">{{day}}</h5>
              <div :key="`group-${day}`" class="files__group">
                <template v-for="file in group">
                  <div :key="file.key" class="files__item">
                    <div class="check-vat" v-if="vatCheck(file)">
                      <span class="fas fa-check"></span>
                    </div>
                    <div v-if="getSender(file)" class="meta" :class="`meta--${getSender(file)}`">
                      <img v-if="getSender(file) === 'adobe'" src="@/assets/images/logo-adobe.png" />
                      <img v-else-if="getSender(file) === 'amazon'" src="@/assets/images/logo-amazon.webp" />
                      <img v-else-if="getSender(file) === 'atlassian'" src="@/assets/images/logo-atlassian.png" />
                      <img v-else-if="getSender(file) === 'cloudflare'" src="@/assets/images/logo-cloudflare.jpg" />
                      <img v-else-if="getSender(file) === 'digi'" src="@/assets/images/logo-digi.png" />
                      <img v-else-if="getSender(file) === 'enel'" src="@/assets/images/logo-enel.png" />
                      <img v-else-if="getSender(file) === 'google'" src="@/assets/images/logo-google.svg" />
                      <img v-else-if="getSender(file) === 'ikea'" src="@/assets/images/logo-ikea.jpg" />
                      <img v-else-if="getSender(file) === 'ing'" src="@/assets/images/logo-ing.png" />
                      <img v-else-if="getSender(file) === 'orange'" src="@/assets/images/logo-orange.png" />
                      <img v-else-if="getSender(file) === 'jetbrains'" src="@/assets/images/logo-jetbrains.png" />
                      <img v-else-if="getSender(file) === 'vodafone'" src="@/assets/images/logo-vodafone.png" />
                      <img v-else-if="getSender(file) === 'zendesk'" src="@/assets/images/logo-zendesk.png" />
                      <img v-else-if="getSender(file) === 'paypal'" src="@/assets/images/logo-paypal.jpg" />
                      <img v-else-if="getSender(file) === 'envato'" src="@/assets/images/logo-envato.png" />
                    </div>
                    <img :src="getImgSrc({ w: 300, auto: 'format' }, file.key)">
                    <el-button  v-on:click.prevent="download(file.key)" round type="primary" size="mini"><span class="fad fa-fw fa-download"></span></el-button>
                    <div v-on:click.prevent="zoom = file.key" class="fad fa-fw fa-search-plus"></div>
                  </div>
                </template>
              </div>

            </template>

          </div>
        </template>
        <template v-else>
          <div class="empty">Empty</div>
        </template>
      </template>
    </div>
    <div class="lightroom" v-if="zoom">
      <div class="lightroom__bg" v-on:click.prevent="zoom = null"></div>
      <div class="lightroom__content-wrapper">
        <div class="lightroom__content">
          <div class="img-wrapper">
            <img v-on:click.prevent="download(zoom)" :src="getImgSrc({ w: 2400, auto: 'format' }, zoom)" />
          </div>
          <el-button v-on:click.prevent="deleteFile(zoom)" type="danger" size="mini" ><span class="fad fa-fw fa-trash"></span> STERGE</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import store from "@/store"
import gql from 'graphql-tag'
import slugify from 'slugify'
import axios from 'axios'
import {ApolloClient} from "@/appsync"
import dayjs from 'dayjs'
export default {
  name: "Inbox",
  data: function(){
    return {
      path: '/',
      zoom: null,
      uploaded_files: []
    }
  },
  watch: {
    path: function(n){
      if( this.path.split('/').length === 4 ){
          store.dispatch('listFiles', n)
      } else {
        store.dispatch('clearFiles')
      }
    }
  },
  computed: {
    crumbs: function(){
      return _.filter(this.path.split('/'), function(p){
        return p.length > 0
      })
    },
    files_list: function(){
      return _.filter( store.getters.files_list, function(file){
        return file.key.toLowerCase().lastIndexOf( '/' ) < file.key.length-1
      })
    },
    files: function(){
      return _.groupBy( this.files_list, function(e){
        return dayjs(e.updated).format('DD/MM/YYYY')
      })
    }
  },
  mounted: function () {
    let vm = this
    store.subscribe((mutation) => {
      if (mutation.type === 'REMOVE_FILE') {
        vm.zoom = null
      }
    })
  },
  methods: {
    getSender: function(file){
      try{
        let has_sender = file.meta && file.meta.sender
        let has_sender_tag = file.tags && ! _.isUndefined( _.find( file.tags, { Key: 'sender' } ) )
        if( ! has_sender && ! has_sender_tag ){
          return false
        }
        else if( has_sender ){
          return has_sender
        }
        else if( has_sender_tag){
          return _.find( file.tags, { Key: 'sender' } ).Value
        } else {
          return false
        }
      }catch (e){
        return false
      }
    },
    vatCheck: function(file){
      try{
        return ! _.isUndefined(_.find( file.tags, { Key: 'VAT', Value: '34559091' } ))
      } catch (e){
        return false
      }
    },
    deleteFile: function(key){
      //let vm = this
      this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        store.dispatch('deleteFile', key)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    normalizeFileName: function(name){
      let fileExtension = name.split('.').pop();
      let fileName = name.replace(fileExtension, '')
      fileName = slugify(fileName, {remove: /[*+~.()'"!:@+=±§#$&`?/><,;\\}{[\]^%]/g}).toLowerCase()
      fileName = fileName + '.' + fileExtension.toLowerCase()
      return fileName
    },
    uploadToS3: async function(n){
      window.console.log('upload', n)
      await store.dispatch('loader', `getSignedUrl-${n.file.name}`)
      let fileName = this.normalizeFileName( n.file.name )
      let vm = this
      try {
        let variables = {
          key: fileName,
          type: n.file.type,
          prefix: vm.path.replace('/','')
        }
        let signedUrl = await ApolloClient.query({
          query: gql `
                  query getSignedUrl( $key: String!, $type: String!, $prefix: String ){
                      getSignedUrl( key: $key, type: $type, prefix: $prefix ){
                          file
                          path
                      }
                   }
              `,
          variables: variables
        })
        window.console.log('raspuns sgined url', signedUrl)
        let response = await axios.put(signedUrl.data.getSignedUrl.path, n.file, {
          headers: {
            'Content-Type': n.file.type
          }
        }).catch(function(e){
          window.console.log('eer', e)
        })
        if( response.status === 200 ){
          await store.dispatch('patchFiles', { key: `${variables.prefix}/${variables.key}` })
        }
      } catch (e) {
        window.console.log('eroare upload', e)
      }
      await store.dispatch('loader', `getSignedUrl-${n.file.name}`)
    },
    beforeAvatarUpload() {
      return true
    },
    download: function(zoom){
      window.open( this.getImgSrc({ dl: zoom }, zoom) , "_blank");
    },
    getMonthValue: function(n){
      if( n <= 9 ){
        return `0${n}`
      }
      return n
    },
    goTo: function(c){
      if( ['extrase', 'acte'].indexOf(c) >= 0 ){
        this.path = `/${c.split('/')[0]}`
      }
      else if( parseInt( c ) >= 13 && this.path.split('/').length > 3 ){
        this.path = this.path.split('/').slice( 0, -1 ).join('/')
      }
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/varaibles";
.lightroom{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  overflow: scroll;
  z-index: 999;

  &__bg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0,0,0,0.8);
    cursor: zoom-out;
  }
  &__content{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
    z-index: 2;

    .img-wrapper{
      margin-bottom: 1rem;
    }

    img{
      background: white;
      width: 100%;
      height: auto;
      //width: auto;
      //max-width: 100%;
      //height: 90vh;
      border-radius: 10px;
      padding: 1rem 1rem 2rem 1rem;
      cursor: pointer;
    }

    .el-button {
      position: fixed;
      right: 1rem;
      bottom: 1rem;
    }
  }
}
.breadcrumbs{
  display: flex;
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;

  .upload{
    align-self: flex-end;
    margin: -0.75rem 0 0 auto;
  }

  div:not(.fa-fw){
    cursor: pointer
  }

  .far,
  .fad,
  .fas{
    margin: 0 0.5rem;
    color: black;

    @at-root .scheme--dark &{
      color: white
    }
  }
}
.file-list{
  font-size: 13px;
  margin-top: 2rem;

  .check-vat{
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: $color-green;
    text-align: center;
    border-radius: 0 3px 0 3px;
    color: white;
    padding: 0.25rem;
    font-size: 1rem;
    text-indent: 1px;
  }

  .empty{
    background-color: rgba(black, 0.05);
    border-radius: 5px;
    padding: 2rem;
    text-align: center;

    @at-root .scheme--dark &{
      background-color: rgba(white, 0.05);
    }
  }

    &__item{
      padding: 0.75rem 0;
      cursor: pointer;

      &:hover{
        background-color: rgba(black, 0.05);

        @at-root .scheme--dark &{
          background-color: rgba(white, 0.05);
        }
      }

      & +.file-list__item{
        border-top: 1px solid rgba(black, 0.1);

        @at-root .scheme--dark &{
          border-top: 1px solid rgba(white, 0.2);
        }
      }
    }

  .fa-fw{
    margin: 0 0.5rem;
  }

  .files{

    &__group{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 2rem;
    }

    img{
      width: 100%;
      height: auto;
    }
    &__item{
      background: white;
      border-radius: 5px;
      padding: 0.5rem;
      position: relative;
      text-align: center;
      border: 1px solid rgba(0,0,0,0.2);

      @at-root .scheme--dark &{
        border-color: transparent;
      }

      &:hover{
        background-color: white;
        border-radius: 3px;
        box-shadow: 0 0 0px 5px rgba($color-blue, 0.5);
        border-color: $color-blue;

        @at-root .scheme--dark &{
          background-color: white;
          border-color: transparent;
        }
      }
      .el-button{
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 0 0 15px 5px rgba(teal,0.3);
        visibility: hidden;
      }
      .fa-search-plus{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5rem;
        opacity: 0;
        cursor: pointer;
        color: $color-blue;
        z-index: 2;
      }
      &:hover{
        .fa-search-plus{
          opacity: 0.7;
        }
        .el-button{
          visibility: visible;
        }
      }
      .meta{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: white;
        border-radius: 5px;
        opacity: 1;
        padding: 0.75rem 1rem;
        max-width: 60px;

        img{
          width: 100%;
          height: auto;
        }

        &--google{
          max-width: 60px;
        }
        &--zendesk{
          max-width: 45px;
        }
        &--vodafone{
          max-width: 75px;
        }
        &--cloudflare{
          max-width: 90px;
        }
        &--paypal{
          max-width: 65px;
          height: 30px;
          overflow: hidden;

          img{
            margin-top: -0.75rem;
          }
        }
        &--adobe{
          height: 30px;
          overflow: hidden;

          img{
            margin-top: -0.75rem;
          }
        }
      }

    }
  }

}
</style>
