<template>
  <div>
    <div class="invoices-list">
      <div class="invoices-list__row invoices-list__header">
          <div>Invoice</div>
          <div>Client</div>
          <div>VAT</div>
          <div>Date</div>
          <div>Total</div>
      </div>
      <div v-for="invoice in invoices" :key="invoice.id" class="invoices-list__row editable" v-on:click.prevent="$router.push({ name: 'Invoice', params: { id: invoice.id, readOnly: true } })">
        <div>{{invoice.serie}} {{invoice.nr}}</div>
        <div>{{invoice.to.name}}</div>
        <div>{{invoice.to.vat}}</div>
        <div>{{invoice.created | dayjs('DD/MM/YYYY')}}</div>
        <div>{{ invoice.total | currency }}</div>
      </div>
    </div>
    <div class="invoices-list__footer">
      <el-button type="primary" size="small" v-if="more" v-on:click.prevent="loadMore()">Load more</el-button>
    </div>
  </div>
</template>

<script>
import store from "@/store"
export default {
name: "History",
  computed: {
    invoices: function(){
      return store.getters.list
    },
    more: function(){
      return store.getters.nextToken !== null
    }
  },
  methods: {
    loadMore: function (){
      store.dispatch('list')
    }
  }
}
</script>

<style lang="scss">
@import "../assets/scss/varaibles";
.invoices-list {
  font-size: 11px;
  width: 100%;
  border-bottom: 1px solid rgba(black, 0.1);

  &__row {
    display: grid;
    grid-template-columns: 60px 1fr 100px 100px 100px;
    padding: 10px 15px;
    grid-column-gap: 15px;
    line-height: 1.4;
    word-break: normal;
    position: relative;

    &.editable{
      cursor: pointer;

      &::before{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    > div{
      &:last-child{
        text-align: right;
      }
    }
  }
  &__header {
    border-bottom: 1px solid rgba(black, 0.1);
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 15px;
  }
  &__footer{
    margin-top: 1rem;
    text-align: center;
  }

  @at-root body.scheme--dark &__header{
    border-bottom: 1px solid rgba($color-text-dark, 0.2);
    color: white;
  }
}
</style>