<template>
  <div>
    <InvoiceStatus :status="form.status" />
    <InvoiceHeader :form="form" v-on:input="setForm" />
    <InvoiceItems :form="form" :euro="euro" v-on:input="setForm" />
    <el-button v-if="form.status < 2" class="add-item-button"
               v-on:click.prevent="form.items.push({name: '', qty: 1, unit: 'ore', price_eu: 100})" type="primary" round
               size="mini"><span class="far fa-plus"></span> Add
    </el-button>
    <InvoiceSubtotal :invoice_total="invoice_total" :invoice_vat="invoice_vat" :invoice_value="invoice_value"/>
    <InvoiceFooter :euro="euro" :euro_date="euro_date"/>
    <div class="save-button" v-if="form.status < 2">
      <el-button v-on:click.prevent="save()" :disabled="witness === JSON.stringify(form)" size="small"
                 type="success">SAVE
      </el-button>
      <el-button v-if="form.status > 0" v-on:click.prevent="save(true)" :disabled="publish_disabled" size="small"
                 type="primary">PUBLISH
      </el-button>
    </div>
  </div>
</template>

<script>

import InvoiceFooter from "@/components/InvoiceFooter"
import InvoiceHeader from "@/components/InvoiceHeader"
import InvoiceItems from "@/components/InvoiceItems"
import InvoiceSubtotal from "@/components/InvoiceSubtotal"
import store from "@/store"
import InvoiceStatus from "@/components/InvoiceStatus";
import Invoice from "@/schemas/invoice"
import { isNull, isUndefined }  from "lodash"
import dayjs from 'dayjs'

let default_form = {
  serie: 'P' ,
  nr: 0,
  date: dayjs().format('YYYY-MM-DD'),
  to: {},
  items: [
    {
      name: 'Servicii editare cod la cerere - programare eveniment',
      note: '',
      unit: 'ore',
      price: 486.90,
      price_eu: 100,
      qty: 1
    }
  ]
}
export default {
  name: "Invoice",
  components: {
    InvoiceStatus,
    InvoiceFooter,
    InvoiceHeader,
    InvoiceItems,
    InvoiceSubtotal
  },
  data: function () {
    return {
      form: new Invoice(default_form).toObject(),
      witness: JSON.stringify(new Invoice(default_form).toObject())
    }
  },
  props: [ 'id', 'readOnly' ],
  computed: {
    nextInvoiceID: function(){
      return store.getters.nextInvoiceID
    },
    euro: function () {
      try {
        return this.id ? this.form.euro : store.getters.euro.rate
      } catch (e) {
        return ''
      }
    },
    euro_date: function () {
      try {
        return this.id ? this.form.euro_date : store.getters.euro.date
      } catch (e) {
        return ''
      }
    },
    invoice_value: function () {
      let total = 0
      this.form.items.forEach(item => {
        total += item.qty * item.price
      })
      return total
    },
    invoice_vat: function () {
      return (19 / 100) * this.invoice_value
    },
    invoice_total: function () {
      return this.invoice_value + this.invoice_vat
    },
    invoice: function(){
      return store.getters.invoice
    },
    publish_disabled: function(){
      try{
        if( this.form.to.vat.length <= 5 ){
          return true
        }
        return false
      } catch (e){
        return true
      }
    }
  },
  methods: {
    save: function (publish) {
      let vm = this
      let form = JSON.parse(JSON.stringify(this.form))
      form.items.map(function (i) {
        i = {
          ...i,
          price: i.price_eu * vm.euro
        }
        return i
      })
      form.currency = 'EUR'
      form.rate = this.euro
      form.rate_date = this.euro_date
      form.total = this.invoice_total
      form.status = form.status || 0
      form.status = publish ? 2 : form.status
      form.ts = dayjs(form.date).unix()
      window.console.log('save', form)
      if( isUndefined( form.id ) ){
        store.dispatch('create', form)
      } else {
        store.dispatch('update', form)
      }
    },
    setForm: function(n){
      this.form = JSON.parse( JSON.stringify(n) )
    }
  },
  watch: {
    nextInvoiceID: function(n){
      this.form.nr = n
    },
    invoice: function(n){
      if( ! isUndefined(n) ){
        this.witness = JSON.stringify(n)
        this.form = JSON.parse(JSON.stringify(n))
      }
    },
    'form.date': function(n){
      if( ! isNull( n ) && n.length === 10 ){
        store.dispatch('getRate', n)
      }
    },
    id: function(n){
      if( isUndefined( n ) ){
        this.form.nr = this.nextInvoiceID
      }
    }
  },
  beforeRouteEnter: async function(to, from, next){
    try{
      if( ! isNull( to.params.id ) && ! isUndefined( to.params.id ) ){
        await store.dispatch('get', to.params.id)
      } else {
        await store.dispatch('latest')
      }
      next()
    } catch (e){
      next()
    }
  },
  beforeRouteLeave: async function (to, from, next){
    this.form = new Invoice(default_form).toObject()
    await store.dispatch('clearInvoice')
    next()
  },
  mounted: function () {
    let vm = this
    if( ! isUndefined( this.invoice ) ){
      window.console.log('set invoice', this.invoice)
      this.form = JSON.parse( JSON.stringify(this.invoice) )
      this.witness = JSON.stringify(this.invoice)
      window.console.log('saved', this.form.to)
    }

    store.subscribe((mutation) => {
      if (mutation.type === 'ADD') {
        vm.$router.push({name: 'Invoice', params: {id: mutation.payload.id, readOnly: true}}).catch()
      }
    })
    if( isUndefined( this.id ) ){
      this.form.nr = this.nextInvoiceID
    }
  }
}
</script>

<style lang="scss">
.save-button {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
</style>