import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import './assets/scss/element.scss';
import './assets/scss/style.scss';
import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
  symbol : '',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: false,
  avoidEmptyDecimals: undefined,
})
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale })

Vue.config.productionTip = false
import router from './router';
import dayjs from 'dayjs';
const ImgixClient = require('imgix-core-js');
const client = new ImgixClient({ domain: 'curly-accounting.imgix.net', secureURLToken: 't63bmJQrceBgmG2e' })
Vue.use({
  install(Vue) {
    Vue.mixin({
      filters: {
        dayjs: function (d, f){
          return dayjs(d).format(f)
        }
      },
      methods: {
        getImgSrc: function (params, image) {
          return client.buildURL(image, params || {});
        }
      }
    })
  }
})

import auth0 from '@/auth'
Vue.use(auth0)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
