<template>
  <div class="client-edit" :class="!readOnly ? 'editable' : ''">
    <h3>CLIENT</h3>
    <div>
      <input  :disabled="readOnly" v-model="to.name" placeholder="Nume client aici" /></div>
    <div class="address">
      <textarea  :disabled="readOnly" v-model="to.address" :rows="3" placeholder="Adresa client aici"></textarea>
    </div>
    <br>
    <div>
      Codul de înregistrare pentru scopuri de TVA: <input class="vat-input" v-model="to.vat" placeholder="RO..."  :disabled="readOnly">
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
export default {
  name: "Client",
  props: ['value', 'read-only'],
  computed: {
    to: {
      get: function(){
        return this.value || {
          name: '',
          address: '',
          vat: ''
        }
      },
      set: function(n){
        this.$emit('input', n)
      }
    }
  },
  mounted: function(){
    this.to = JSON.parse( JSON.stringify( this.value || this.to ) )
  },
  watch: {
    'to.vat': _.debounce(async function(n){
      let missing = _.isUndefined( this.to.address ) || _.isUndefined(this.to.name) || this.to.address.length === 0 || this.to.name.length === 0
      if( missing && ! _.isUndefined( this.to.vat ) && this.to.vat.length > 5 ){
        try{
          let { data } = await axios.get(`https://ofdhn73fq0.execute-api.eu-central-1.amazonaws.com/dev/get-company?id=${n}`)
          this.to.address = data.adresa
          this.to.name = data.denumire
        } catch (e){
          window.console.log('eroare', e)
        }
      }
    }, 800)
  }
}
</script>

<style lang="scss" scoped>
.client-edit {

  textarea,
  input {
    border: none;
    background-color: transparent;
    color: var(--color-text);
    outline: none;
    width: 100%;
    padding: 0;
    height: inherit;
    line-height: inherit;
    font-size: inherit;
    resize: none;
  }

  input {
    font-weight: bold;
  }

  .vat-input {
    display: inline-block;
    width: 5rem;
  }
}
</style>