import SchemaObject from "schema-object";
const Client = new SchemaObject({
    name: String,
    vat: String,
    address: String
})
const Item = new SchemaObject({
    name: {
        type: String,
        default: ''
    },
    note: {
        type: String,
        default: ''
    },
    unit: {
        type: String,
        default: ''
    },
    qty: {
        type: Number,
        default: 0
    },
    price: {
        type: Number,
        default: 0
    },
    price_eu: {
        type: Number,
        default: 0
    }
})

export default new SchemaObject({
    id: String,
    serie: {
        type: String,
        default: ''
    },
    nr: {
        type: Number,
        default: ''
    },
    date: {
        type: String,
        default: ''
    },
    to: {
        type: Client
    },
    items: {
        type: [Item],
        default: []
    },
    currency: {
        type: String,
        default: ''
    },
    rate: {
        type: Number,
        default: 0
    },
    date_date: {
        type: String,
        default: ''
    },
    total: {
        type: Number,
        default: 0
    },
    status: {
        type: Number,
        default: 0
    },
    ts: {
        type: Number,
        default: 0
    }
})