<template>
  <div class="sub-total">
    <div class="sub">
      <div>SUBTOTAL</div>
      <div class="value">{{ invoice_value | currency }}</div>
      <div class="vat">{{ invoice_vat | currency }}</div>
    </div>
    <div class="checkout">
      <div><h4>TOTAL DE PLATĂ <small>- inclusiv TVA -</small></h4></div>
      <div class="final-price">{{ invoice_total | currency }}</div>
    </div>
    <Instructions />
  </div>
</template>

<script>
import Instructions from "@/components/Instructions"
export default {
name: "InvoiceSubtotal",
  props: ['invoice_total', 'invoice_vat', 'invoice_value'],
  components: {
    Instructions
  },
}
</script>

<style scoped>

</style>