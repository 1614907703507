import gql from 'graphql-tag';
export default gql `
    query listFiles($prefix: String){
        listFiles(
            prefix: $prefix
        ){
            key
            updated
            meta{
                sender
            }
            tags{
                Key
                Value
            }
         }
    }
`