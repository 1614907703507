import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import _ from 'lodash'
Vue.use(Vuex)

export default new Vuex.Store({
    state: state,
    actions: actions,
    mutations: mutations,
    getters: {
        color_scheme: function (state){
            return state.color_scheme
        },
        list: function (state){
            return state.list
        },
        logged_user: function(state){
            return state.logged_user || JSON.parse(localStorage.getItem('user'))
        },
        logged_in: function(){
            return new Date().getTime() < parseInt(localStorage.getItem('expires_at'))
        },
        setup: function(state){
            return state.setup
        },
        loaders: function(state){
            return state.loaders
        },
        loading: function(state){
            return state.loaders.length > 0
        },
        euro: function(state){
            return state.euro
        },
        invoice: function(state){
            return state.invoice
        },
        nextToken: function(state){
          return state.nextToken
        },
        nextInvoiceID: function(state){
            try{
                return parseInt(_.find(state.latest, {status: 2}).nr) + 1
            } catch (e){
                return 12
            }
        },
        files_list: function(state){
            return state.files_list
        }
    },
    strict: true,
    plugins: []
})