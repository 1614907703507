import gql from 'graphql-tag'
export default gql `
    query listLatestInvoices($limit: Int){
        listLatestInvoices(
            limit: $limit
        ){
            items{
                id
                date
                serie
                nr
                status
                ts
            }
         }
    }
`