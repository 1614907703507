<template>
  <div class="footer">Termen de plată: 3 zile; Cota de TVA: 19%; Toate preturile sunt exprimate în RON. 1&euro; =
    {{ euro | currency({fractionCount: 4}) }} RON ({{ euro_date | dayjs('DD/MM/YYYY') }})
  </div>
</template>

<script>
export default {
name: "InvoiceFooter",
  props: ['euro', 'euro_date']
}
</script>

<style scoped>

</style>