import Vue from 'vue'
import Router from 'vue-router'
import NewInvoice from '@/components/NewInvoice'
import History from '@/components/History'
import Home from '@/components/Home'
import Inbox from '@/components/Inbox'
import store from "@/store"
import _ from "lodash"

Vue.use(Router)

async function handleLogin(to, from, next) {
    if( _.isNull( to.query.login ) && !router.app.$auth.isAuthenticated() ){
        await router.app.$auth.handleAuthentication().then(() => {
            store.dispatch( 'set_logged_user' )
        }).catch(function(e){
            window.console.log('er login', e)
            router.app.$auth.login()
        })
        if( !store.getters.setup ){
            await store.dispatch( 'setup' )
        }
        next({ name: 'Invoice', params: { action: 'new' } })
    } else if( router.app.$auth.isAuthenticated() ){
        if( !store.getters.setup ){
            await store.dispatch( 'setup' )
        }
        next()
    } else {
        router.app.$auth.login()
    }
}

async function guardRoute(to, from, next) {
    if( new Date().getTime() < parseInt(localStorage.getItem('expires_at')) ){
        if( !store.getters.setup ){
            await store.dispatch( 'setup' )
        }
        next();
    } else {
        //router.app.$auth.login()
    }
}



const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            beforeEnter: handleLogin,
        },
        {
            path: '/inbox',
            name: 'Inbox',
            component: Inbox,
            beforeEnter: guardRoute
        },
        {
            path: '/invoices',
            name: 'Invoices',
            component: History,
            beforeEnter: guardRoute
        },
        {
            path: '/invoices/:id',
            name: 'Invoice',
            props: true,
            component: NewInvoice,
            beforeEnter: guardRoute
        },
        {
            path: '/new',
            name: 'NewInvoice',
            props: true,
            component: NewInvoice,
            beforeEnter: guardRoute
        }
    ]
})

router.beforeEach((to, from, next) => {
    let matched = window.matchMedia('prefers-color-scheme: dark').matches;
    store.dispatch('changeTheme', matched ? 'light' : 'dark')
    next()
})

export default router