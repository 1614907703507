import gql from 'graphql-tag'
export default gql `
    query getInvoice($id: ID!){
        getInvoice(
            id: $id
        ){
            id
            date
            serie
            nr
            items{
                name
                qty
                price
                price_eu
                note
                unit
            }
            to{
                name
                address
                vat
            }
            total
            currency
            rate
            rate_date
            status
            ts
         }
    }
`