import gql from 'graphql-tag'
export default gql `
    query listInvoices($limit: Int, $nextToken: String){
        listInvoices(
            limit: $limit,
            nextToken: $nextToken
        ){
            items{
                id
                date
                serie
                nr
                items{
                    name
                    qty
                    price
                    price_eu
                    note
                    unit
                }
                to{
                    name
                    address
                    vat
                }
                total
                currency
                rate
                rate_date
                status
                ts
            },
            nextToken
         }
    }
`