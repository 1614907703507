import auth0 from 'auth0-js'
import Vue from 'vue'

export const webAuth = new auth0.WebAuth({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
    redirectUri: `${process.env.VUE_APP_AUTH0_REDIRECT_URI}?login`,
    responseType: 'token id_token',
    connection:'Username-Password-Authentication'
})

let auth = new Vue({
    data: function(){
      return {
          token: localStorage.getItem('id_token'),
          accessToken: localStorage.getItem('access_token'),
          expiresAt: parseInt(localStorage.getItem('expires_at')),
          user: JSON.parse(localStorage.getItem('user'))
      }
    },
    watch: {
        token: function(id_token) {
          localStorage.setItem('id_token', id_token)
        },
        accessToken:function(accessToken) {
            localStorage.setItem('access_token', accessToken)
        },
        expiresAt: function(expiresIn) {
            let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime())
            localStorage.setItem('expires_at', expiresAt)
        },
        user: function(user) {
            localStorage.setItem('user', JSON.stringify(user))
        }
    },
    methods: {
        login() {
            webAuth.authorize()
        },
        register() {
            webAuth.authorize({
                mode: 'signUp'
            })
        },
        logout() {
            return new Promise(() => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('id_token')
                localStorage.removeItem('expires_at')
                localStorage.removeItem('user')
                webAuth.logout({returnTo: process.env.VUE_APP_AUTH0_REDIRECT_URI + '?logout'})
            })
        },
        isAuthenticated() {
            return new Date().getTime() < this.expiresAt
        },
        handleAuthentication() {
            return new Promise((resolve, reject) => {
                webAuth.parseHash((err, authResult) => {
                    if (authResult && authResult.accessToken && authResult.idToken) {
                        this.expiresAt = authResult.expiresIn
                        this.accessToken = authResult.accessToken
                        this.token = authResult.idToken
                        this.user = authResult.idTokenPayload
                        resolve()

                    } else if (err) {
                        this.logout()
                        reject(err)
                    }

                })
            })
        }
    }
})

export default {
    install: function(Vue) {
        Vue.prototype.$auth = auth
    }
}