<template>
  <div class="invoice-status" :class="`invoice-status--${getStatus()}`">{{ getStatus() }}</div>
</template>

<script>
export default {
  name: "InvoiceStatus",
  props: ['status'],
  methods: {
    getStatus: function(){
      if( this.status === 2 ){
        return 'closed'
      }
      if( this.status === 1 ){
        return 'opened'
      }
      return 'draft'
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/varaibles";
  .invoice-status{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1rem;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    border-bottom-left-radius: 10px;

    &--draft{
      background: rgba(0,0,0,0.1);

      @at-root .scheme--dark &{
        background: rgba(white,0.1);
      }
    }
    &--closed {
      background: rgba($color-green, 0.25);
      color: $color-green;

      @at-root .scheme--dark &{
        background: rgba($color-green, 0.15);
      }
    }
    &--opened {
      background: rgba($color-blue, 0.15);
      color: $color-blue;
    }
  }
</style>