import Vue from "vue"
import _ from "lodash"
import cleanDeep from "clean-deep"
import Invoice from "@/schemas/invoice"
export default {
    TOGGLE_LOADER: function(state, loader){
        if( state.loaders.indexOf( loader ) < 0 ){
            state.loaders.push(loader)
        } else {
            state.loaders.splice( state.loaders.indexOf( loader ), 1 )
        }
    },
    LOG (state, ev){
        state.log.push(ev)
    },
    UNLOG (state, k){
        state.log.splice(k, 1)
    },
    READY(state){
        state.setup = true
    },
    SET_LOGGED_USER(state, user){
        state.logged_user = user
    },
    SET_THEME(state, theme){
        state.color_scheme = theme
    },
    SET_LATEST_LIST(state, list){
        state.latest = list
    },
    SET_FILES_LIST(state, list){
        state.files_list = list
    },
    CLEAR_FILES(state){
      state.files_list = []
    },
    SET_RATE(state, rate){
      state.euro = rate
    },
    REMOVE_FILE(state, key){
      state.files_list.splice(_.findIndex( state.files_list, { key } ), 1)
    },
    PATCH_FILES(state, file){
        if( _.isUndefined( _.find(state.files_list, { key: file.key } ) ) ){
            state.files_list.push(file)
        }
    },
    SET_LIST(state, list) {
        list = _.map(list, function (v) {
            return v
        })
        _.each(list, function (i) {
            if( _.isUndefined( _.find( state.list, { id: i.id } ) ) ){
                state.list.push(i)
            } else {
                Vue.set( state.list, _.findIndex( state.list, { id: i.id } ), i )
            }
        })
    },
    PATCH_LIST: function (state, item){
      let index = _.findIndex(state.list, {id: item.id})
      if( index >= 0 ){
          state.list[index] = new Invoice(cleanDeep(item)).toObject()
      }
    },
    SET_NEXT_TOKEN: function (state, token) {
        state.nextToken = token
    },
    ADD: function(state, item){
        state.list.push( cleanDeep(item) )
    },
    ADD_LATEST: function(state, item){
        state.latest.push( cleanDeep(item) )
    },
    SET: function(state, item){
        state.invoice = new Invoice(cleanDeep(item)).toObject()
    },
    CLEAR: function(state){
        state.invoice = undefined
    }
}